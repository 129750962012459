import React from "react";

/**
 * 404
 */
export default function NotFound() {
  return (
    <div>
      <h1>Error 404: NotFound</h1>
      <p>This page does not exist...</p>
    </div>
  );
}
